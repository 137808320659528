import { ArrowLeftOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Switch,
  Typography,
  Upload,
  message,
} from "antd";
import axios from "axios";
import moment from "moment";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React, { useContext, useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { Link, useNavigate } from "react-router-dom";
import { uploadFile } from "react-s3";
import Prouser from "../../assets/images/user.png";
import AccountSideNav from "../../components/layout/AccountSideNav";
import Footer from "../../components/layout/Footer";
import Header from "../../components/layout/Header";
import { Config } from "../../config/s3Config";
import apiPath from "../../constants/api_urls";
import { useAppContext } from "../../context/AppContext";
import { AuthContext } from "../../context/AuthContext";
import { dateString } from "../../helper/functions";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import lang from "../../helper/langHelper";
window.Buffer = window.Buffer || require("buffer").Buffer;

const config = {
  dirName: "mynt/profile",
  bucketName: Config.S3_BUCKET,
  region: Config.REGION,
  accessKeyId: Config.ACCESS_KEY,
  secretAccessKey: Config.SECRET_ACCESS_KEY,
};

function AccountIndex() {
  const { request } = useRequest();

  const { isMobile, setLanguage, language } = useAppContext();
  const { setIsLoggedIn, setUserProfile, userProfile } =
    useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [profile, setProfile] = useState();
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const fetchUser = async (user) => {
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      };
      const response = await axios.get(
        apiPath.baseURL + `/${apiPath.profile}`,
        {
          headers,
        }
      );

      const result = response.data.data;

      if (result?.data?.length) {
        setProfile(result?.data[0]);
        setUserProfile(result?.data[0]);
        console.log(
          result?.data[0]?.userLanguage,
          "result?.data[0]?.userLanguage"
        );
        setLanguage(result?.data[0]?.userLanguage);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onClose = (values) => {
    setLoading(true);

    request({
      url: "/app/auth/delete-profile",
      method: "PUT",
      // data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          console.log(data);
          setIsLoggedIn(false);
          localStorage.removeItem("userProfile");
          localStorage.removeItem("token");
          ShowToast(data.message, Severty.SUCCESS);
          setUserProfile();
          navigate("/");
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  const onChange = (language) => {
    let payload = { userLanguage: language };
    console.log(
      language,
      "---------lanuhefiuaghdfighsdofiuashiouuuuuuuuuusd-------------->"
    );

    setLoading(true);
    request({
      url: `${apiPath.editProfile}/${userProfile._id}`,
      method: "PUT",
      data: payload,
      onSuccess: (data) => {
        console.log(data, "data-------------->");
        setLoading(false);
        if (data.status) {
          localStorage.setItem("languageSet", language);
          ShowToast(data.message, Severty.SUCCESS);
          if (refresh) refresh();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  useEffect(() => {
    fetchUser();
  }, [refresh]);

  return (
    <>
      <section className="main">
        <Header />
        <div className="account-outers">
          <Row>
            {!isMobile ? <AccountSideNav /> : <BackArrow />}
            <Col span={24} md={16} lg={18} xl={18} xxl={15} className="mx-auto">
              <div className="account-main">
                <div className="table-headding">{lang("Account")}</div>
                <span>
                  {lang(
                    "View your profile info and manage your trading interface."
                  )}
                </span>
                <div className="profile-card">
                  <div className="account-tital">
                    <h4> {lang("Profile info")}</h4>
                  </div>
                  <Card>
                    <h4>{`${profile?.name ?? ""} ${
                      profile?.lastName ?? ""
                    }`}</h4>
                    <div className="profile-section">
                      {/* <div className="user-profile">
                       <img src={profile?.image ? profile.image : Prouser} /> 
                       <Image
                          src={profile?.image ? profile.image : Prouser}
                        ></Image>{" "} 
                      </div> */}
                      <div className="profile-details">
                        {/* <div className="tital-in-profile">
                                                    <h6 className="">Account Status</h6>
                                                    <h4>Pending</h4>
                                                    <p><img src={Iball} />Your identity verification is currently being reviewed.</p>
                                                </div> */}
                        <div className="tital-in-profile">
                          <h6 className="">{lang("Email")}</h6>
                          <h4>{profile?.email}</h4>
                        </div>
                        {/* <div className="tital-in-profile">
                          <h6 className="">Date of birth</h6>
                          <h4>
                            {profile?.dob
                              ? dateString(profile.dob, "YYYY-MM-DD")
                              : "-"}
                          </h4>
                        </div> */}
                        {profile?.address && (
                          <div className="tital-in-profile">
                            <h6 className="">{lang("Home address")}</h6>
                            <h4>{profile?.address ?? "-"}</h4>
                            <h4>
                              {profile?.city ?? null}, {profile?.state ?? null}{" "}
                              {profile?.postalCode ?? null} IN
                            </h4>
                          </div>
                        )}
                        <div>
                          <Button
                            className="edite-profile-btn"
                            onClick={showModal}
                          >
                            {lang("Edit")}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
                {/* <Address data={profile} refresh={() => setRefresh(prev => !prev)} /> */}
                {/* <Shipping data={profile} refresh={() => setRefresh(prev => !prev)} /> */}
                <Portfolio
                  data={profile}
                  refresh={() => setRefresh((prev) => !prev)}
                />
                <div className="profile-card">
                  <div className="account-tital">
                    <h4>{lang("Language selection")}</h4>
                    <span>{lang("Your preferred language for mynt")}</span>
                  </div>
                  <Card>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="Portfolio-display">
                        <h6>{lang("English")}</h6>
                      </div>
                      <div className="Portfolio-display-swich">
                        <Select
                          className="form-of-type"
                          placeholder={lang("Select a Language")}
                          options={[
                            {
                              value: "en",
                              label: "English",
                            },
                            {
                              value: "es",
                              label: "Española",
                            },
                          ]}
                          value={language}
                          onChange={(value) => {
                            setLanguage(value);
                            onChange(value);
                          }}
                        />
                        {/* <Button className="edite-profile-btn">Change</Button> */}
                      </div>
                    </div>
                  </Card>
                </div>
                <div className="profile-card">
                  <div className="account-tital">
                    <h4>{lang("Your mynt account")}</h4>
                    <span>{lang("Manage your personal account")}</span>
                  </div>
                  <Card>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="Portfolio-display">
                        <h6>{lang("Account closure")}</h6>
                      </div>
                      <div className="Portfolio-display-swich">
                        <Popconfirm
                          title={lang("Close the account")}
                          descripFtion={lang(
                            "Are you sure to close this account?"
                          )}
                          onConfirm={onClose}
                          onCancel={() => null}
                          okText={lang("Yes")}
                          cancelText={lang("No")}
                          okButtonProps={{ className: "yes-button" }}
                          cancelButtonProps={{ className: "no-button" }}
                        >
                          <Button className="visa-in-btn" type="button">
                            {lang("Close account")}
                          </Button>
                        </Popconfirm>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <Footer />
      {isModalOpen && (
        <Edit
          show={isModalOpen}
          hide={() => setIsModalOpen(false)}
          data={profile}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}
    </>
  );
}

const Edit = ({ show, hide, data, refresh }) => {
  const [form] = Form.useForm();
  const [file, setFile] = useState([]);
  const [image, setImage] = useState();
  const [loading, setLoading] = useState(false);
  const { request } = useRequest();
  const FileType = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/avif",
    "image/webp",
    "image/gif",
  ];

  const beforeUpload = (file) => {
    if (FileType.includes(file.type)) {
    } else {
      message.error(lang("File format is not correct"));
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error(lang(`Image must be smaller than 5 MB!`));
      return false;
    }
    return true;
  };

  const handleRemove = async () => {
    // alert("remove")
    setFile([]);
    setImage();
  };

  const handleChange = async (event) => {
    const { file } = event;
    setFile([file]);
    uploadFile(file, config)
      .then((data) => {
        const fileData = {
          uid: file.uid,
          name: file.name,
          status: "done",
          url: data.location,
          thumbUrl: data.location,
        };
        setFile([fileData]);
        setImage(data.location);
        console.log(image, "fileData");
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    form.setFieldsValue({
      ...data,
      dob: data.dob ? moment(data.dob) : moment(),
    });
    setImage(data.image);
  }, [data]);

  const onCreate = (values) => {
    const { name, dob, email, lastName } = values;

    let payload = { name, dob, email, lastName };

    setLoading(true);

    if (image) payload.image = image;

    request({
      url: `${apiPath.editProfile}/${data._id}`,
      method: "PUT",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          hide();
          if (refresh) refresh();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  return (
    <Modal
      title="Edit Profile"
      width={570}
      className="modal01"
      open={show}
      onOk={hide}
      onCancel={hide}
      footer={false}
    >
      <Form onFinish={onCreate} id="create" form={form} layout="vertical">
        <Row>
          <Col className="mb-3" span={24}>
            <Form.Item
              className="username-form"
              label={lang("Name")}
              name="name"
              rules={[
                {
                  required: true,
                  message: lang("Please enter name"),
                },
              ]}
              normalize={(value) => value.trimStart()}
            >
              <Input
                className="form-of-type"
                placeholder={lang("Please Enter Name")}
              />
            </Form.Item>
          </Col>
          <Col className="mb-3" span={24}>
            <Form.Item
              className="username-form"
              label={lang("last Name")}
              name="lastName"
              rules={[
                {
                  required: true,
                  message: lang("Please enter name"),
                },
              ]}
              normalize={(value) => value.trimStart()}
            >
              <Input
                className="form-of-type"
                placeholder={lang("Enter Name")}
              />
            </Form.Item>
          </Col>
          {/* <Col className="mb-3" span={24}>
            <Form.Item
              className="username-form"
              label={"Date of Birth"}
              name="dob"
              rules={[
                {
                  required: true,
                  message: "Please select date of birth!",
                },
              ]}
            >
              <DatePicker
                className="form-of-type w-100"
                defaultValue={moment("2015/01/01", "YYYY/MM/DD")}
                format={"YYYY/MM/DD"}
                disabledDate={(current) =>
                  current && current > moment().endOf("day")
                }
              />
            </Form.Item>
          </Col> */}
          <Col className="mb-3" span={24}>
            <Form.Item
              className="username-form"
              label={lang("Email Address")}
              name="email"
            >
              <Input
                disabled
                className="form-of-type"
                placeholder={lang("Enter Email Address")}
              />
            </Form.Item>
          </Col>
          {/* <Col span={24}>
            <Form.Item
              label="Profile Image"
              name={"image"}
              rules={[
                {
                  validator: (_, value) => {
                    if (image) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("Image is required"));
                  },
                },
              ]}
            >
              <Upload
                listType="picture"
                maxCount={1}
                beforeUpload={beforeUpload}
                customRequest={handleChange}
                onRemove={handleRemove}
                fileList={file}
              >
                {file && file.length > 0 ? null : (
                  <Button icon={<UploadOutlined />}>Upload</Button>
                )}
              </Upload>
              <div
                className="imgPreview mt-3"
                style={{ maxHeight: "200px", overflow: "hidden" }}
              >
                {" "}
                <Image src={image ? image : ""}></Image>{" "}
              </div>
            </Form.Item>
          </Col> */}
          <Col span={24}>
            <div className="d-flex gap-3 mt-3">
              <Button
                onClick={hide}
                className="visa-in-btn portfolio-btn"
                type="button"
              >
                {" "}
                {lang("Close")}{" "}
              </Button>
              <Button
                loading={loading}
                className="ant-btn-default portfolio-btn"
                type="button"
                htmlType="submit"
              >
                {" "}
                {lang("Save")}{" "}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

const Address = ({ show, hide, data, refresh }) => {
  const [form] = Form.useForm();
  const [file, setFile] = useState([]);
  const [image, setImage] = useState();
  const [loading, setLoading] = useState(false);
  const { request } = useRequest();
  const FileType = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/avif",
    "image/webp",
    "image/gif",
  ];

  useEffect(() => {
    form.setFieldsValue({ ...data });
  }, [data]);

  const onCreate = (values) => {
    const { postalCode, address, state, city } = values;

    let payload = { postalCode, address, state, city };

    setLoading(true);

    if (image) payload.image = image;

    request({
      url: `${apiPath.editProfile}/${data._id}`,
      method: "PUT",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(
            lang("Billing address updated successfully"),
            Severty.SUCCESS
          );
          if (refresh) refresh();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  return (
    <div className="profile-card">
      <div className="account-tital">
        <h4>{lang("Billing Address")}</h4>
        <span>
          {lang(
            "For security, a billing address must be on file before placing your first mynt order."
          )}
        </span>
      </div>
      <div className="signup-form p-0">
        <Form
          form={form}
          onFinish={onCreate}
          layout="vertical"
          className="row-col"
        >
          <Row gutter={12}>
            <Col span={24} md={24}>
              <Form.Item
                className="username-form billing-form"
                label={lang("Street Address")}
                name="address"
              >
                <Input className="form-of-type" placeholder="US" />
              </Form.Item>
            </Col>
            <Col span={24} md={8}>
              <Form.Item
                className="username-form billing-form"
                label={lang("City")}
                name="city"
              >
                <Input className="form-of-type" placeholder="US" />
              </Form.Item>
            </Col>
            <Col span={24} md={8}>
              <Form.Item
                className="username-form billing-form"
                label={lang("State")}
                name="state"
              >
                <Input className="form-of-type" placeholder="AL" />
              </Form.Item>
            </Col>
            <Col span={24} md={8}>
              <Form.Item
                className="username-form billing-form"
                label={lang("Postal Code")}
                name="postalCode"
              >
                <Input className="form-of-type" placeholder="61897" />
              </Form.Item>
            </Col>
          </Row>
          <div className="password-page-btn">
            <Button
              loading={loading}
              htmlType="submit"
              className="security-btn"
            >
              {lang("Save Changes")}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

const Shipping = ({ show, hide, data, refresh }) => {
  const [form] = Form.useForm();
  const [file, setFile] = useState([]);
  const [image, setImage] = useState();
  const [loading, setLoading] = useState(false);
  const { request } = useRequest();
  const FileType = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/avif",
    "image/webp",
    "image/gif",
  ];

  useEffect(() => {
    form.setFieldsValue({ ...data });
  }, [data]);

  const onCreate = (values) => {
    setLoading(true);
    request({
      url: `${apiPath.editProfile}/${data._id}`,
      method: "PUT",
      data: values,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(lang("Shipping address updated"), Severty.SUCCESS);
          if (refresh) refresh();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  const onChange = (e) => {
    console.log(`switch to ${e}`, e.target.checked);
    onCreate({ shipping_address_same: e.target.checked });
  };

  return (
    <div className="profile-card">
      <div className="account-tital">
        <h4>{lang("Shipping Address")}</h4>
        <span>
          {lang(
            " A shipping address must be on file before placing your first redemption order. All redemption orders will be shipped to this address."
          )}
        </span>
      </div>
      <div className="">
        <Checkbox
          checked={data?.shipping_address_same ?? false}
          className="address-check"
          onChange={onChange}
        >
          <p className="check-text-name">{lang("Same as Billing Address")} </p>{" "}
        </Checkbox>
      </div>
    </div>
  );
};

const Portfolio = ({ show, hide, data, refresh }) => {
  const [form] = Form.useForm();
  const [file, setFile] = useState([]);
  const [image, setImage] = useState();
  const [loading, setLoading] = useState(false);
  const { request } = useRequest();
  const FileType = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/avif",
    "image/webp",
    "image/gif",
  ];

  useEffect(() => {
    console.log(data,"data-----")
    form.setFieldsValue({ ...data });
  }, [data]);

  const onCreate = (values) => {
    setLoading(true);
    request({
      url: `${apiPath.editProfile}/${data._id}`,
      method: "PUT",
      data: values,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(lang("Portfolio display setting updated"), Severty.SUCCESS);
          localStorage.setItem("theme", values.dark_theme);
          setTimeout(() => window.location.reload(), 200);
          if (refresh) refresh();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  const onChange = (e) => {
    console.log(`switch to ${e}`, e);
    //onCreate({ shipping_address_same: e.target.checked })
  };

  return (
    <div className="profile-card">
      <div className="account-tital">
        <h4>{lang("Portfolio display")}</h4>
      </div>
      <Card>
        <div className="Portfolio-display-section">
          <div className="Portfolio-display">
            <h6>{lang("Hide dust")}</h6>
            <span>{lang("Fractional amounts 0.01 notional")}</span>
          </div>
          <div className="Portfolio-display-swich">
            <Switch
            // checked={data?.hide_dust}
            // onChange={(value) => onCreate({ hide_dust: value })}
            />
          </div>
        </div>
        <div className="Portfolio-display-section">
          <div className="Portfolio-display">
            <h6>{lang("Dark Theme")}</h6>
            <span>
              {lang("Change the website theme in dark mode or light mode.")}
            </span>
          </div>
          <div className="Portfolio-display-swich">
            <Switch
              checked={data?.dark_theme}
              onChange={(value) => onCreate({ dark_theme: value })}
            />
          </div>
        </div>
      </Card>
    </div>
  );
};

export const BackArrow = ({}) => {
  const navigate = useNavigate();
  return (
    <div className="fillter-sidebar">
      <Link onClick={() => navigate(-1)} className="sidebar-btn">
        <ArrowLeftOutlined />
      </Link>
    </div>
  );
};

export default AccountIndex;
