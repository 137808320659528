import { Button, Card, Col, Row, Skeleton, Tag } from "antd";
import { RightOutlined } from "@ant-design/icons";

import axios from "axios";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React, { useEffect, useState } from "react";
import { CardBody, CardFooter, CardTitle, Container } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel3";
import DOMPurify from "dompurify";
import "react-phone-input-2/lib/style.css";
import { Link, useNavigate } from "react-router-dom";
import useDebounce from "../../hooks/useDebounce";
import useRequest from "../../hooks/useRequest";
import Myntcard from "../../assets/images/myntcard.jpg";
import creditCard from "../../assets/images/creditCard.png";
import Dawnerrow from "../../assets/images/red-dawn.svg";
import Worldmap from "../../assets/images/map.png";
import Frame from "../../assets/images/Frame.png";

import Footer from "../../components/layout/Footer";
import Header from "../../components/layout/Header";
import apiPath from "../../constants/api_urls";
import { useAuthContext } from "../../context/AuthContext";
import NewsModal from "./NewsModal";
import { dateString } from "../../helper/functions";
import OrderCard from "../../components/order/OrderCard";
import { useAppContext } from "../../context/AppContext";
import Dollor from "../../assets/images/component-doller.svg";
import componentgold from "../../assets/images/component-gold.svg";
import componentsilvar from "../../assets/images/component-silvar.svg";
import lang from "../../helper/langHelper";
import { RWebShare } from "react-web-share";

function Home() {
  const { request } = useRequest();
  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [visible, setVisible] = useState(false);
  const debouncedSearchText = useDebounce(searchText, 300);
  const [viewData, setViewData] = useState();
  const [visibleView, setVisibleView] = useState(false);
  const navigate = useNavigate();
  const { isLoggedIn, userProfile } = useAuthContext();

  const owlCarouselOptions = {
    items: 3,
    loop: true,
    margin: 10,
    nav: true,
    dots: false,
    responsive: {
      0: {
        items: 1.1,
      },
      600: {
        items: 2.3,
      },
      1000: {
        items: 3,
      },
    },
  };

  return (
    <>
      <section className="main">
        <Header />
        <Container>
          <div className="home-outers">
            <Row gutter={24}>
              <Col span={24} lg={16} className="order-2 order-lg-1">
                {<AccountBalance />}

                <Row gutter={12}>
                  {/* <Col span={24} md={24} lg={12} className="mb-3">
                    <div className="home-card-p">
                      <Card>
                        <CardBody className="home-card-body">
                          <h4>Get started today with as little as $10.</h4>
                        </CardBody>
                        <Button
                          onClick={() => {
                            navigate("/market");
                          }}
                          className="ant-btn-default white-btn"
                          type="button"
                        >
                          Start trading
                        </Button>
                      </Card>
                    </div>
                  </Col> */}
                  <Col sm={24} md={24} lg={24} className="mb-3">
                    <div className="home-card-p">
                      <Card>
                        <CardBody className="home-card-body">
                          <h4>{lang("Set Up Auto Invest")}</h4>
                          <p>
                            {lang(
                              "Build your portfolio with automatic investments."
                            )}
                          </p>
                        </CardBody>
                        <Button
                          onClick={() => {
                            if (!isLoggedIn) return navigate("/login");
                            navigate("/AutoInvest");
                          }}
                          className="ant-btn-default "
                          type="button"
                        >
                          {lang("Start Now")}
                        </Button>
                      </Card>
                    </div>
                  </Col>
                </Row>
                {/* <TransactionList /> */}
                <Resources />
                <News />
              </Col>
              <Col sm={24} lg={8} className="order-1 order-lg-2">
                {userProfile && (
                  <div className="d-block d-lg-none">
                    <Row>
                      <Col span={24}>
                        <div className="buy-header-outer">
                          <h6>
                            {lang("Hi")} {userProfile?.name ?? lang("Guest")}
                          </h6>
                          <div className="top-buy-header">
                            <div className="bl-text">
                              <h6>{lang("Balance")}</h6>
                            </div>
                            {/* <div className="gg-btn">
                              <Button>Buy Gold</Button>
                            </div> */}
                          </div>
                          <div className="buy-prices">
                            <h2>
                              {userProfile?.accountValue
                                ? parseFloat(
                                    userProfile.accountValue.toFixed(2)
                                  )
                                : // Uncomment the following lines to format as currency
                                  // .toLocaleString("en-US", {
                                  //   style: "currency",
                                  //   currency: "USD",
                                  //   currencyDisplay: "code",
                                  // })
                                  "0.00"}
                            </h2>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                )}
                <OrderCard />
                {<MobileView />}
                <Card className="credit-card-box">
                  <CardTitle>
                    {lang("The Mynt Debit Card")}
                    {/* &trade; */}
                  </CardTitle>
                  <CardBody>
                    <img className="card-imagas" src={creditCard} />
                    {/* <p className="card-line-text">
                      mynt Foundation Derivatives is currently not available in your region. Sign up to get notified when Derivatives become available in US.
                      </p> */}
                  </CardBody>
                  {/* <CardFooter>
                    {/* <Button className="ant-btn-default mt-3" type="button">
                      See if you pre-qualify
                    </Button> *
                  </CardFooter> */}
                </Card>
                <Card className="credit-card-box bg-green">
                  <CardBody className="p-0">
                    <div className="locker-body">
                      <img className="card-imagas " src={Frame} />
                      <h6>{lang("A safe place for your assets")} </h6>
                      <p>
                        {lang(
                          "Your investments are held in your name and backed 1:1 in a Loomis vault in Switzerland"
                        )}{" "}
                      </p>
                    </div>
                    {/* <p className="card-line-text">
                      mynt Foundation Derivatives is currently not available in your region. Sign up to get notified when Derivatives become available in US.
                      </p> */}
                  </CardBody>
                  {/* <CardFooter>
                    {/* <Button className="ant-btn-default mt-3" type="button">
                      See if you pre-qualify
                    </Button> *
                  </CardFooter> */}
                </Card>
                <Card className="invite-frnd mt-3 mb-3">
                  <CardBody className="home-card-body ern-card">
                    <h4>
                      {lang(
                        "Earn $50k in gold when you refer friends to Mynt."
                      )}
                    </h4>
                  </CardBody>
                  <CardFooter>

                  <RWebShare
                data={{
                  title: lang("Check out this page!"),
                  text: lang("Here is some interesting content to share."),
                  url: window.location.href,
                }}
                disableNative={true}
                sites={[
                  "linkedin",
                  "facebook",
                  "whatsapp",
                  "twitter",
                  "reddit",
                ]}
                onClick={() => console.log("Shared successfully!")}
              >
                {/* <Button onClick={() => handleShareNavigate()}>
                  <img src={Share2Img} alt="Share Icon" />
                </Button> */}
                <div className="share-link-now">
                      <span>https://myntgold.com/</span>
                      <Button
                        className="ant-btn-default"
                        type="button"
                        onClick={() => {
                          // handleShareNavigate()
                        }}
                      >
                        {lang("Share")}
                      </Button>
                    </div>
              </RWebShare>

                    
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
        <Footer />
      </section>
    </>
  );
}

const AccountBalance = ({}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [gold, setGold] = useState({
    price: 0,
    quantity: 0,
  });
  const [silver, setSilver] = useState({
    price: 0,
    quantity: 0,
  });

  const { isLoggedIn, userProfile, setUserProfile } = useAuthContext();
  const { refreshBalance } = useAppContext();

  const navigate = useNavigate();
  const { request } = useRequest();

  const fetchUser = async (user) => {
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      };
      const response = await axios.get(
        apiPath.baseURL + `/${apiPath.profile}`,
        {
          headers,
        }
      );
      console.log(response.data.data, "response.data.data");
      const result = response?.data?.data;

      if (result?.data?.length) {
        setUserProfile(result?.data[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!userProfile) return;
    fetchUser(userProfile?._id);
  }, [refreshBalance, isLoggedIn]);

  useEffect(() => {
    if (!userProfile) return;
    console.log(userProfile, "metalBalance");

    if (!userProfile.metalBalance) return;

    userProfile.metalBalance.map((item) => {
      if (item.categoryId == "66069035fa639b23dcf4b3a6") {

        const price = item.quantity * item.currentPrice;
        console.log("gold price", price, item.quantity, item.currentPrice);
        setGold({
          price,
          quantity: item.quantity,
        });
      } else {
        const price = item.quantity * item.currentPrice;
        setSilver({
          price,
          quantity: item.quantity,
        });
      }
    });
  }, [userProfile]);

  return (
    <div className="re-change-pro">
      {userProfile && (
        <Row>
          <Col span={24}>
            <div className="buy-header-outer">
              <h6>
                {lang("Hi")} {userProfile?.name ?? "Guest"}
              </h6>
              <div className="top-buy-header">
                <div className="bl-text">
                  <h6>{lang("Balance")}</h6>
                </div>
                {/* 
              <div className="gg-btn">
                <Button onClick={() => {
                  navigate('/gold')
                }}>
                  Buy Gold</Button>
              </div>
               */}
              </div>
              <div className="buy-prices">
                <h2>
                  {"$"}
                  {userProfile?.accountValue
                    ? parseFloat(userProfile.accountValue?.toFixed(2))
                    : // .toLocaleString("en-US", {
                      //   style: "currency",
                      //   currency: "$",
                      //   currencyDisplay: "code",
                      // }) 
                      `0.00`}
                </h2>
              </div>
            </div>
          </Col>
        </Row>
      )}

      <Row gutter={6}>
        <Col span={24} md={24} lg={8} className="mb-3">
          <div className="">
            <div className="account-btn-header">
              <Button
                onClick={() => {
                  navigate("/gold");
                }}
              >
                <div className="individual-account w-100 justify-content-between">
                  <img width="40px" src={componentgold} />
                  <div className="individuel-text">
                    <h4>{lang("Gold")}</h4>
                    <p>
                      {gold?.quantity?.toFixed(3)} OZ ( {"$"}
                      {
                        parseFloat(gold.price.toFixed(2))
                        // .toLocaleString(
                        //   "en-US",
                        //   {
                        //     style: "currency",
                        //     currency: "USD",
                        //     currencyDisplay: "code",
                        //   },
                        // )
                      }
                      )
                    </p>
                  </div>
                  <div className="right-angel-errow">
                    <RightOutlined />
                  </div>
                </div>
              </Button>
            </div>
          </div>
        </Col>
        <Col span={24} md={24} lg={8} className="mb-3">
          <div className="account-btn-header">
            <Button
              onClick={() => {
                navigate("/silver");
              }}
            >
              <div className="individual-account w-100 justify-content-between">
                <img width="40px" src={componentsilvar} />

                <div className="individuel-text">
                  <h4>{lang("Silver")}</h4>
                  <p>
                    {silver?.quantity?.toFixed(3)} OZ ( {"$"}
                    {
                      parseFloat(silver.price.toFixed(2))
                      // .toLocaleString(
                      //   "en-US",
                      //   {
                      //     style: "currency",
                      //     currency: "USD",
                      //     currencyDisplay: "code",
                      //   },
                      // )
                    }
                    )
                  </p>
                </div>
                <div className="right-angel-errow">
                  <RightOutlined />
                </div>
              </div>
            </Button>
          </div>
        </Col>
        <Col span={24} md={24} lg={8} className="mb-3">
          <div className="account-btn-header">
            <Button
              onClick={() => {
                if (!isLoggedIn) {
                  return navigate("/login");
                }
                navigate("/CashBalance");
              }}
            >
              <div
                className="individual-account w-100 justify-content-between"
                style={{ display: "flex", alignItems: "center" }}
              >
                <div className="d-flex align-items-center gap-3">
                  <img width="40px" src={Dollor} />
                  <div
                    className="individuel-text"
                    // style={{ marginRight: "100px" }}
                  >
                    <h4>USD</h4>
                    <p>
                      $
                      {userProfile
                        ? parseFloat(userProfile.cashBalance.toFixed(2))
                        : `0.00`}
                    </p>
                  </div>
                </div>
                <div
                  className="right-angel-errow"
                  style={{ marginLeft: "4px" }}
                >
                  <RightOutlined />
                </div>
              </div>
            </Button>
          </div>
        </Col>
        {/* <Col span={24} md={24} lg={12} className="mb-3">
          <div className="">
            <div className="account-btn-header">
              <Button className="feature-main">
                <div className="individual-account  w-100 justify-content-between">
                  <div className="individuel-text">
                    <h4>New feature!</h4>
                    <p>Reeating Gold Buys learn more</p>
                  </div>
                  <div className="right-angel-errow">
                    <RightOutlined />
                  </div>
                </div>
              </Button>
            </div>
          </div>
        </Col> */}
      </Row>
    </div>
  );
};

const MobileView = ({}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [gold, setGold] = useState({
    price: 0,
    quantity: 0,
  });
  const [silver, setSilver] = useState({
    price: 0,
    quantity: 0,
  });

  const { isLoggedIn, userProfile, setUserProfile } = useAuthContext();
  const { refreshBalance } = useAppContext();

  const navigate = useNavigate();

  useEffect(() => {
    if (!userProfile) return;
    console.log(userProfile, "metalBalance");

    if (!userProfile.metalBalance) return;

    userProfile.metalBalance.map((item) => {
      if (item.categoryId == "66069035fa639b23dcf4b3a6") {
        const price = item.quantity / item.currentPrice;
        console.log("gold price", price, item.quantity, item.currentPrice);
        setGold({
          price,
          quantity: item.quantity,
        });
      } else {
        const price = item.quantity / item.currentPrice;
        setSilver({
          price,
          quantity: item.quantity,
        });
      }
    });
  }, [userProfile]);

  return (
    <div className="d-block d-lg-none">
      <Row gutter={24}>
        <Col span={24} md={24} lg={12} className="mb-3">
          <div className="">
            <div className="account-btn-header">
              <Button
                className="gold-btn-s"
                onClick={() => {
                  navigate("/gold");
                }}
              >
                <div className="individual-account w-100 justify-content-between">
                  <div className="d-flex align-items-center gap-3">
                    <img width="40px" src={componentgold} />
                    <div className="individuel-text gold-text">
                      <h4>{lang("Gold")}</h4>
                      <p>
                        {gold?.quantity?.toFixed(3)} OZ (
                        {parseFloat(gold?.price?.toFixed(2)).toLocaleString(
                          "en-US",
                          {
                            style: "currency",
                            currency: "USD",
                            currencyDisplay: "code",
                          }
                        )}
                        )
                      </p>
                    </div>
                  </div>

                  <div className="right-angel-errow">
                    <RightOutlined />
                  </div>
                </div>
              </Button>
            </div>
          </div>
        </Col>
        <Col span={24} md={24} lg={12} className="mb-3">
          <div className="account-btn-header">
            <Button
              onClick={() => {
                navigate("/silver");
              }}
            >
              <div className="individual-account w-100 justify-content-between">
                <div className="d-flex align-items-center gap-3">
                  <img width="40px" src={componentsilvar} />
                  <div className="individuel-text">
                    <h4>{lang("Silver")}</h4>
                    <p>
                      {silver?.quantity?.toFixed(3)} OZ (
                      {parseFloat(silver?.price?.toFixed(2)).toLocaleString(
                        "en-US",
                        {
                          style: "currency",
                          currency: "USD",
                          currencyDisplay: "code",
                        }
                      )}
                      )
                    </p>
                  </div>
                </div>
                <div className="right-angel-errow">
                  <RightOutlined />
                </div>
              </div>
            </Button>
          </div>
        </Col>
        <Col span={24} md={24} lg={12} className="mb-3">
          <div className="account-btn-header">
            <Button
              onClick={() => {
                if (!isLoggedIn) {
                  return navigate("/login");
                }
                navigate("/CashBalance");
              }}
            >
              <div className="individual-account w-100 justify-content-between">
                <div className="d-flex align-items-center gap-3">
                  <img
                    width="40px"
                    src={Dollor}
                    style={{ marginRight: "8px" }}
                  />
                  <div className="individuel-text">
                    <h4>USD</h4>
                    <p>
                      {userProfile
                        ? parseFloat(
                            userProfile.cashBalance.toFixed(2)
                          ).toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                            currencyDisplay: "code",
                          })
                        : `0.00`}
                    </p>
                  </div>
                </div>
                <div className="right-angel-errow">
                  <RightOutlined />
                </div>
              </div>
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const TransactionList = ({}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const { isLoggedIn } = useAuthContext();
  const { refreshBalance } = useAppContext();

  const { request } = useRequest();

  const getResources = () => {
    setLoading(true);
    request({
      url: `/app/transaction/getTransaction`,
      method: "GET",
      onSuccess: (res) => {
        const { data } = res;
        console.log(data, "getTransaction");
        setData(data.data);
        setLoading(false);
      },
      onError: (err) => setLoading(false),
    });
  };

  useEffect(() => {
    if (!isLoggedIn) return;
    getResources();
  }, [isLoggedIn, refreshBalance]);

  useEffect(() => {
    console.log(data, "data lllll");
  }, [data]);

  if (loading) return [1, 2, 3, 4].map((item) => <Skeleton ac />);

  return !isLoggedIn || data.length == 0 ? null : (
    <Row gutter={12} className="mb-3">
      <Col span={24}>
        <div className="tital transactions-tital ">
          <h4 className="m-0">{lang("Recent Transactions")}</h4>
          <Link to="/transactions">{lang("View all")}</Link>
        </div>
      </Col>
      <Col span={24}>
        <Card className="transaction-section">
          <div className="transaction-history">
            <ul>
              {data?.map((item) => (
                <li>
                  <div className="transaction-history-left">
                    <h6>
                      {item.orderId.typeOfTransaction == "Buy" && (
                        <>
                          {" "}
                          {lang("Bought")} {item.orderId.categoryId.categories}{" "}
                        </>
                      )}
                      {item.orderId.typeOfTransaction == "Sell" && (
                        <>
                          {" "}
                          {lang("Sell")} {item.orderId.categoryId.categories}{" "}
                        </>
                      )}
                      {item.orderId.typeOfTransaction == "Swap" && (
                        <>
                          {" "}
                          {lang("Swap")} {item.orderId.categoryId.categories}{" "}
                        </>
                      )}
                    </h6>
                    <span>{dateString(item.created_at)}</span>
                  </div>
                  <div className="transaction-history-right">
                    <h4>
                      {item.amount?.toFixed(2)?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                        currencyDisplay: "code",
                      })}
                    </h4>
                    <span className="text-danger1">
                      {item.quantity?.toFixed(3)} oz
                    </span>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

const Resources = ({}) => {
  const { language } = useAppContext();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const { request } = useRequest();
  const navigate = useNavigate();

  const getResources = () => {
    setLoading(true);
    request({
      url: `/common/getResources`,
      method: "GET",
      onSuccess: (res) => {
        const { data } = res;
        console.log(data, "RESouce");
        setData(data.data);
      },
    });
  };

  useEffect(() => {
    getResources();
  }, []);

  const owlCarouselOptions = {
    items: 3,
    loop: true,
    margin: 10,
    nav: true,
    dots: false,
    responsive: {
      0: {
        items: 1.1,
      },
      600: {
        items: 2.3,
      },
      1000: {
        items: 3,
      },
    },
  };

  return data.length == 0 ? null : (
    <Row gutter={12} className=" ">
      <Col span={24}>
        <div className="tital">
          <h4>{lang("Resources for you")}</h4>
        </div>
      </Col>

      <OwlCarousel {...owlCarouselOptions} className="owl-theme owl-resources">
        {data?.map((item) => (
          <Col
            span={24}
            key={item._id}
            onClick={() => navigate(`/${item._id}/resource`)}
          >
            <div className="resources-card-p">
              <Card style={{ cursor: "pointer" }}>
                <CardBody className="resources-card-body card-home">
                  <img
                    src={item.image}
                    onError={(e) =>
                      (e.target.src =
                        "https://b2btobacco.s3.amazonaws.com/mint/file_1712037538213.jpg")
                    }
                  ></img>
                  <h5>{language == "en" ? item.title : item.es_title}</h5> 
                  {/* <p>{item.description}</p> */}
                  {/* <div dangerouslySetInnerHTML={{ __html: "gvfbhk" }}></div> */}

                  {/* <div dangerouslySetInnerHTML={{__html: `${item.description}}`}}></div> */}
                </CardBody>
              </Card>
            </div>
          </Col>
        ))}
      </OwlCarousel>
    </Row>
  );
};

const News = ({}) => {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState();
  const navigate = useNavigate();

  const { request } = useRequest();

  const getNews = () => {
    setLoading(true);
    request({
      url: `/common/getNews`,
      method: "GET",
      onSuccess: (res) => {
        const { data, status } = res;
        console.log(data, "News");
        setData(data.articles);
      },
    });
  };

  useEffect(() => {
    getNews();
  }, []);

  const owlCarouselOptions02 = {
    items: 3,
    loop: true,
    margin: 10,
    nav: true,
    dots: false,
    responsive: {
      0: {
        items: 1.1,
      },
      600: {
        items: 1.1,
      },
      1000: {
        items: 1,
      },
    },
  };

  return;
  // data.length == 0 ? null : (
  // <Row gutter={12} className="mt-3 ">
  //   <Col span={24}>
  //     <div className="tital">
  //       <h4>Recent News</h4>
  //     </div>
  //   </Col>
  //   <OwlCarousel
  //     {...owlCarouselOptions02}
  //     className="owl-theme owl-resources"
  //   >
  //     {data?.map((item) => (
  //       <Col span={24} key={item._id}>
  //         <div className="resources-card-p">
  //           <Card>
  //             <CardBody className="resent-gold-card-body">
  //               <div className="resent-gold">
  //                 <div className="resent-img-gold">
  //                   <img
  //                     src={item.urlToImage}
  //                     onError={(e) =>
  //                       (e.target.src =
  //                         "https://b2btobacco.s3.amazonaws.com/mint/file_1712037538213.jpg")
  //                     }
  //                   ></img>
  //                 </div>
  //                 <div className="resent-text-gold">
  //                   <h5>{item.title}</h5>
  //                   <p>{item.description}</p>
  //                 </div>
  //               </div>
  //               <Button
  //                 className="resent-btn mt-3"
  //                 onClick={() => {
  //                   setShow(true);
  //                   setSelected(item);
  //                 }}
  //               >
  //                 Read more
  //               </Button>
  //             </CardBody>
  //           </Card>
  //         </div>
  //       </Col>
  //     ))}
  //   </OwlCarousel>

  //   {show && (
  //     <NewsModal
  //       show={show}
  //       hide={() => {
  //         setShow(false);
  //         setSelected();
  //       }}
  //       data={selected}
  //     />
  //   )}
  // </Row>
  // );
};

export default Home;
